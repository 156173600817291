<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo">
          <b-img :src="logoUic" />
        </b-link>

        <b-card-title class="mb-1"> Verify Email </b-card-title>
        <b-card-text class="mb-2" v-if="!success">
          Token not valid, try again!
        </b-card-text>

        <b-card-text class="mb-2" v-else>
          Successfully verify {{ userEmail }}
        </b-card-text>

        <!-- form -->

        <!-- <p class="text-center mt-2">
          <b-link :to="{ name: 'auth-login-v1' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p> -->
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import API from '@/api'
import logoUic from '@/assets/images/logo-uic.png'

export default {
  components: {
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BImg,
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      success: false,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',

      logoUic,
    }
  },
  mounted: function () {
    this.submit()

  },
  methods: {

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submit()
        }
      })
    },
    async submit() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
        const form = {
          token: this.$route.params.token,
        }
        console.log(form)
        const { data } = await API.auth.verifyEmail(form)
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
        this.handleMsgSuccess('Successfully Verify Email')
        this.success = true
        this.userEmail = data.email

      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Token tidak valid, silahkan coba lagi!')
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }


    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
